import type { BeforeMaskedStateChangeStates } from 'react-input-mask'
import type { ApiFormCountry } from '../../model/types/apiFormCountry'

type PhoneMaskChangeProps = Pick<
  ApiFormCountry,
  'phoneTitle' | 'pastBugPhone' | 'pastBug8Phone'
>

export const phoneMaskChange = (props: PhoneMaskChangeProps) => {
  const { phoneTitle, pastBugPhone, pastBug8Phone } = props

  return ({ currentState, nextState }: BeforeMaskedStateChangeStates) => {
    const replacePastPhone = (bugPhone: string) => {
      return {
        ...currentState,
        value: currentState.value.replace(bugPhone, phoneTitle),
      }
    }

    const isPastBugPhone = currentState?.value.includes(pastBugPhone)
    const isPastBug8Phone =
      pastBug8Phone && currentState?.value.includes(pastBug8Phone)

    if (isPastBugPhone) return replacePastPhone(pastBugPhone)
    if (isPastBug8Phone) return replacePastPhone(pastBug8Phone)

    return nextState
  }
}
