import type { ApiFormCountry } from '../../types/apiFormCountry'

export const MD_PHONE_INFO: Omit<ApiFormCountry, 'country' | 'icon'> = {
  phoneMask: '+\\3\\7\\3\\ (99) 999 999',
  pastBugPhone: '+373 (+373',
  pastBug8Phone: '+373 (+8',
  phoneTitle: '+373',
  phoneMatchesReg: /\+373\s\((2[2-9]|[3-9][0-9])\)\s[0-9]{3}\s[0-9]{3}/,
  phoneMatchesErr:
    'Проверьте код мобильного оператора!\n Код должен быть в диапазоне (22 - 99) \n Пример: +373 (XX) XXX XXX',
}
