import type { IFormInputs } from '../model/types/IFormInputs'

export const sendUserData = async (data: IFormInputs) => {
  const newData = JSON.stringify({ data: [data] }, null, ' ')

  await fetch('/send.php', {
    method: 'post',
    mode: 'no-cors',
    body: newData,
  })
}
