import type { ApiFormCountry } from '../../types/apiFormCountry'

export const GE_PHONE_INFO: Omit<ApiFormCountry, 'country' | 'icon'> = {
  phoneMask: '+\\9\\9\\5\\ (99) 999 999',
  pastBugPhone: '+995 (+995',
  pastBug8Phone: '+995 (+8',
  phoneTitle: '+995',
  phoneMatchesReg: /\+995\s\((5[0-9]|32|79|22)\)\s[0-9]{3}\s[0-9]{3}/,
  phoneMatchesErr:
    'Проверьте код мобильного оператора!\n Доступные коды операторов: \n 50-59, 32, 79, 22 \n Пример: +995 (XX) XXX XXX',
}
