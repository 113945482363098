import { phoneCallOptions } from '../../model/consts/phoneCallOptions'
import { ConnectForm } from '../ConnectForm/ConnectForm'

type InputsHiddenProps = {
  modalId?: string
}

export const InputsHidden = (props: InputsHiddenProps) => {
  const { modalId } = props

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
      }) => (
        <>
          <input
            type="hidden"
            {...register('callHours')}
            name="callHours"
            defaultValue={phoneCallOptions[1].value}
          />
          <input
            {...register('country')}
            id={'country' + modalId}
            name={'country'}
            type="hidden"
          />
          <input
            {...register('ip')}
            id={'ip' + modalId}
            name={'ip'}
            type="hidden"
          />
          <input
            {...register('external_id')}
            id={'external_id' + modalId}
            name={'external_id'}
            type="hidden"
          />
          <input
            {...register('metric')}
            id={'metric' + modalId}
            name={'metric'}
            type="hidden"
          />
          <input
            {...register('gaid')}
            id={'gaid' + modalId}
            name={'gaid'}
            type="hidden"
          />
          <input
            {...register('sub1')}
            id={'sub1' + modalId}
            name={'sub1'}
            type="hidden"
          />
          <input
            {...register('sub2')}
            id={'sub2' + modalId}
            name={'sub2'}
            type="hidden"
          />
          <input
            {...register('sub3')}
            id={'sub3' + modalId}
            name={'sub3'}
            type="hidden"
          />
          <input
            {...register('sub4')}
            id={'sub4' + modalId}
            name={'sub4'}
            type="hidden"
          />
          <input
            {...register('sub5')}
            id={'sub5' + modalId}
            name={'sub5'}
            type="hidden"
          />
          <input
            {...register('sub6')}
            id={'sub6' + modalId}
            name={'sub6'}
            type="hidden"
          />
          <input
            {...register('sub7')}
            id={'sub7' + modalId}
            name={'sub7'}
            type="hidden"
          />
          <input
            {...register('apps_id')}
            id={'apps_id' + modalId}
            name={'apps_id'}
            type="hidden"
          />
          <input
            {...register('devKey')}
            id={'devKey' + modalId}
            name={'devKey'}
            type="hidden"
          />
          <input
            {...register('bundle')}
            id={'bundle' + modalId}
            name={'bundle'}
            type="hidden"
          />
          <input
            {...register('status')}
            id={'status' + modalId}
            name={'status'}
            type="hidden"
          />
        </>
      )}
    </ConnectForm>
  )
}
