import Select, { components } from 'react-select'
import { phoneCallOptions } from '../../model/consts/phoneCallOptions'

type SelectCallHoursProps = {
  setValue: (name: 'callHours', value: string) => void
}

export const SelectCallHours = (props: SelectCallHoursProps) => {
  const { setValue } = props

  const handleSelectChange = (selectedOption: any) => {
    setValue('callHours', selectedOption.value)
  }

  const Control = ({ children, ...props }: any) => (
    <components.Control {...props}>Позвонить{children}</components.Control>
  )

  const customStyles = {
    option: (base: any, state: any) => ({
      ...base,
      color: state.isSelected ? '#fff' : '#000',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      backgroundColor: state.isSelected ? '#72BF44' : '#fff',
      cursor: 'pointer',
    }),
    control: (base: any) => ({
      ...base,
      boxSizing: 'border-box',
      padding: '0 0 0 16px',
      height: '46px',
      color: '#000',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '1px',
      borderRadius: '6px',
      border: '1px solid #D1D2D4',
      cursor: 'pointer',

      backgroundColor: '#F5F5F5',
    }),
  }

  return (
    <Select
      onChange={handleSelectChange}
      options={phoneCallOptions}
      defaultValue={phoneCallOptions[1]}
      components={{ Control }}
      styles={customStyles}
      isSearchable={false}
    />
  )
}
