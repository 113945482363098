import CardIcon from 'shared/assets/icons/CardIcon.webp'
import ProtectIcon from 'shared/assets/icons/ProtectIcon.webp'
import PortfolioIcon from 'shared/assets/icons/PortfolioIcon.webp'
import MouseIcon from 'shared/assets/icons/MouseIcon.webp'
import { sumCurrency } from 'shared/consts/sumCurrency'

type CardsContentType = {
  id: number
  icon: string
  title: string
  desc1: string
  desc2?: string
}

export const cardsContent: CardsContentType[] = [
  {
    id: 1,
    icon: CardIcon,
    title: 'Быстрое открытие счета и консультация наставника',
    desc1:
      'Инвестировать может каждый человек старше 21-го года. Вам не требуется опыт в торговле на бирже - мы научим Вас всему сами.',
    desc2:
      'После открытия инвест-счета с Вами свяжется Ваш куратор. Он передаст инструкции для старта и обсудит стратегию безопасного заработка.',
  },
  {
    id: 2,
    icon: ProtectIcon,
    title: 'Полное возмещение депозита за счет государства',
    desc1:
      'Инвестируя Вы гарантировано сохраняете вложенную Вами сумму (Закон от 7 июля 2006 года № 169-III)',
    desc2:
      'Если возникнет кризис на бирже или в стране случится инфляция, вы получите свои сбережения назад в долларовом эквиваленте.',
  },
  {
    id: 3,
    icon: PortfolioIcon,
    title: 'Трейдинг «на автопилоте»',
    desc1:
      'Наша передовая технология FREEDOM.AI анализирует сделки и динамику курсов ценных бумаг на бирже за счет сложного квантового компьютера.',
    desc2:
      'Эти данные помогают добиваться результата в 73,9% сделок - в долгосрочной перспективе каждый инвестор выходит в плюс.',
  },
  {
    id: 4,
    icon: MouseIcon,
    title: 'Доступный вход и защита от падения курса',
    desc1: `Для начала торговли ценными бумагами Вам не требуется много денег. До конца месяца Вы можете открыть депозит всего от $150.`,
    desc2: `На Вашем счету будут только долларовые активы, и в случае падения курса в вашей стране Вы ничего не потеряете.`,
  },
]
