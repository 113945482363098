import type { ApiFormCountry } from '../../types/apiFormCountry'

export const KZ_PHONE_INFO: Omit<ApiFormCountry, 'country' | 'icon'> = {
  phoneMask: '+7 (999) 999 99 99',
  pastBugPhone: '+7 (+7',
  pastBug8Phone: '+7 (+8',
  phoneTitle: '+7',
  phoneMatchesReg:
    /^\+7\s?\(?(7)(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-8])\)?\s?\d\d\d\s?\d\d\s?\d\d/,
  phoneMatchesErr:
    'Проверьте код мобильного оператора!\n Код должен быть в диапазоне (700 - 778) \n Пример: +7 (7XX) XXX XX XX',
}
