import useAppContext from 'app/store/useAppContext'
import { SubmitAffirmationModal } from 'features/affirmation'
import classNames from 'shared/lib/class-names'
import ManImg from 'shared/assets/images/money-protect/ManImg.webp'

import cls from './ProtectMoney.module.scss'

export default function ProtectMoney() {
  const {
    store: { isMobile, isMobileSmall },
  } = useAppContext()

  const title = <h3 className={cls.header__title}>Ваши деньги под защитой</h3>

  return (
    <section
      className={classNames('flex__col_center', cls.page)}
      id="protect_money"
    >
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div>
          {title}

          <p className={cls.header__desc}>
            Инвестируйте свои деньги только в надежные компании и проекты
          </p>
        </div>

        <div className={cls.content}>
          <div className={cls.left_block}>
            <img src={ManImg} alt="ManImg" />
          </div>
          <div className={cls.right_block}>
            <div className={cls.card}>
              <p className={cls.card__desc}>
                <span>
                  <span>Freedom Finance</span> - дочерняя компания Freedom
                  Holding Corp с капитализацией $3,8 млрд.
                </span>
                <span>
                  Мы являемся <span>единственным</span> брокером, представленным
                  в Европе и Азии, с листингом на Nasdaq.
                </span>
                <span>
                  Обладаем <span>лицензией CySEC</span> и долгосрочным кредитным
                  рейтингом "BB" от{' '}
                  <span>Standard & Poor's Global Ratings</span>.
                </span>
                <span>
                  Подотчетны регуляторам — CySEC и SEC. Прозрачны, надежны и
                  открыты для каждого.
                </span>
              </p>
            </div>
          </div>
          {isMobileSmall && (
            <div className={cls.button}>
              <SubmitAffirmationModal
                btnContent="начать инвестировать"
                btnProps={{ variant: 'default' }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
