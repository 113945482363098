import KazakhstanIcon from 'shared/assets/icons/country/kazakhstan.webp'
import GeorgiaIcon from 'shared/assets/icons/country/georgia.webp'
import ArmeniaIcon from 'shared/assets/icons/country/armenia.webp'
import BelarusIcon from 'shared/assets/icons/country/belarus.webp'
import MoldovaIcon from 'shared/assets/icons/country/moldova.webp'
// import UzbekistanIcon from 'shared/assets/icons/country/uzbekistan.webp'
import AzerbaijanIcon from 'shared/assets/icons/country/azerbaijan.webp'

import type { ApiFormCountry } from '../types/apiFormCountry'
import { KZ_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesKZ'
import { AM_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesAM'
import { AZ_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesAZ'
import { BY_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesBY'
import { GE_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesGE'
import { MD_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesMD'
// import { UZ_PHONE_INFO } from '../consts/formMatchesMultiGeo/formMatchesUZ'

export const apiFormCountries: ApiFormCountry[] = [
  {
    country: 'KZ',
    icon: KazakhstanIcon,
    ...KZ_PHONE_INFO,
  },
  {
    country: 'GE',
    icon: GeorgiaIcon,
    ...GE_PHONE_INFO,
  },
  {
    country: 'AM',
    icon: ArmeniaIcon,
    ...AM_PHONE_INFO,
  },
  {
    country: 'BY',
    icon: BelarusIcon,
    ...BY_PHONE_INFO,
  },
  {
    country: 'MD',
    icon: MoldovaIcon,
    ...MD_PHONE_INFO,
  },
  // {
  //   country: 'UZ',
  //   icon: UzbekistanIcon,
  //   ...UZ_PHONE_INFO,
  // },
  {
    country: 'AZ',
    icon: AzerbaijanIcon,
    ...AZ_PHONE_INFO,
  },
]
