import LogoIcon from 'shared/assets/icons/logo-full.webp'
import styles from './Logo.module.scss'
import classNames from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'

interface Logo {
  className?: string
}

export const Logo = (props: Logo) => {
  const { className } = props

  const location = useLocation()

  return (
    <NavLink
      to={{
        pathname: ROUTES.MAIN,
        search: location.search,
      }}
      className={classNames(styles.logo, className)}
    >
      <img src={LogoIcon} alt="logo" />
    </NavLink>
  )
}
