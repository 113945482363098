import type { ApiFormCountry } from '../../types/apiFormCountry'

export const AZ_PHONE_INFO: Omit<ApiFormCountry, 'country' | 'icon'> = {
  phoneMask: '+\\9\\9\\4\\ (99) 999 999',
  pastBugPhone: '+994 (+994',
  pastBug8Phone: '+994 (+8',
  phoneTitle: '+994',
  phoneMatchesReg: /\+994\s\((50|51|55|60|70|77|99)\)\s[0-9]{3}\s[0-9]{3}/,
  phoneMatchesErr:
    'Проверьте код мобильного оператора!\n Доступные коды операторов: \n 50, 51, 55, 60, 70, 77, 99 \n Пример: +994 (XX) XXX XXX',
}
