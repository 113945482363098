import type { ApiFormCountry } from '../../model/types/apiFormCountry'
import { apiFormCountries } from '../../model/consts/apiCountries'

export const getApiFormData = (country?: string) => {
  const foundCountry = apiFormCountries.find(
    (_country) => _country.country === country,
  ) as ApiFormCountry

  if (!foundCountry) {
    return apiFormCountries.find(
      (_country) => _country.country === 'KZ',
    ) as ApiFormCountry
  }

  return foundCountry
}
