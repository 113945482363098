import type { ApiFormCountry } from '../../types/apiFormCountry'

export const BY_PHONE_INFO: Omit<ApiFormCountry, 'country' | 'icon'> = {
  phoneMask: '+\\3\\7\\5\\ (99) 999 99 99',
  pastBugPhone: '+375 (+375',
  pastBug8Phone: '+375 (+8',
  phoneTitle: '+375',
  phoneMatchesReg: /\+375\s\((25|29|33|44)\)\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/,
  phoneMatchesErr:
    'Проверьте код мобильного оператора!\n Доступные коды операторов: 25, 29, 33, 44 \n Пример: +375 (XX) XXX XX XX',
}
