import type { ApiFormCountry } from '../../types/apiFormCountry'

export const AM_PHONE_INFO: Omit<ApiFormCountry, 'country' | 'icon'> = {
  phoneMask: '+\\3\\7\\4\\ (99) 999 999',
  pastBugPhone: '+374 (+374',
  pastBug8Phone: '+374 (+8',
  phoneTitle: '+374',
  phoneMatchesReg:
    /\+374\s\((10|11|12|33|41|43|44|46|47|55|77|91|93|94|95|96|97|98|99)\)\s[0-9]{3}\s[0-9]{3}/,
  phoneMatchesErr:
    'Проверьте код мобильного оператора!\n Доступные коды операторов: \n 10, 11, 12, 33, 41, 43, 44, 46, 47, 55, 77, 91, 93, 94, 95, 96, 97, 98, 99 \n Пример: +374 (XX) XXX XXX',
}
